import React from 'react';
import {useParams} from 'react-router-dom';
import {
  Row,
  Col,
  Table,
} from 'react-bootstrap';

import config from '../config.js';
import {useFileworksAuth} from '../hooks/useFileworksAuth';

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const FileLink = ({id, name, attachment}) => {
  const {jwt} = useFileworksAuth();
  const {uuid} = useParams();

  const params = {token: jwt};
  if (attachment) {
    params.attachment = true
  }
  const qs = new URLSearchParams(params).toString()

  return (
    <a
      href={`${config.apiUrl}/download/${uuid}/${id}?${qs}`}
      target="_blank"
    >
      {attachment ? 'Download' : name}
    </a>
  );
}

const FileRow = ({file}) => {
  if(!file) return null;

  if (file.pending) {
    return <ClientFileRow {...{file}} />
  }
  else {
    return <ServerFileRow {...{file}} />
  }
};

const ClientFileRow = ({file}) => {
  const date = new Date()

  return (
    <tr>
      <td>
      </td>
      <td>
        {file.name}
      </td>
      <td>{formatBytes(file.size)}</td>
      <td>{date.toLocaleString('en-US')}</td>
      <td>
        Processing...
      </td>
    </tr>
  );
};

const ServerFileRow = ({file}) => {
  const date = new Date(file.createdTime);

  return (
    <tr>
      <td>
        <img src={file.iconLink} alt={file.mimeType} />
      </td>
      <td>
        <FileLink {...file} />
      </td>
      <td>{formatBytes(file.size)}</td>
      <td>{date.toLocaleString('en-US')}</td>
      <td>
        <FileLink {...file} attachment={true} />
      </td>
    </tr>
  );
};

function Download({files}) {
  const {uuid} = useParams();

  return (
    <Col className="container-download" lg={12}>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Size</th>
            <th>Uploaded At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, i) => <FileRow key={i} file={file} />)}
        </tbody>
      </Table>
    </Col>
  );
}

export default Download;
