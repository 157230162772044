import jwtDecode from 'jwt-decode';

export function localStorageForKey(JWT_KEY) {
  const getLocalStorageJwt = () => window.localStorage.getItem(JWT_KEY)
  const setLocalStorageJwt = (jwt) =>  window.localStorage.setItem(JWT_KEY, jwt);
  const unsetLocalStorageJwt = () =>  window.localStorage.removeItem(JWT_KEY);

  const tryJwtDecode = () => {
    const jwt = getLocalStorageJwt();
    try {
      return jwtDecode(jwt);
    } catch (err) {
      console.error(`jwt ${JWT_KEY} decode error`, err);
      return false;
    }
  }

  return {
    getLocalStorageJwt,
    setLocalStorageJwt,
    unsetLocalStorageJwt,
    tryJwtDecode,
  }
}
