import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { Container, Row, } from 'react-bootstrap';
import useFetch from 'use-http';

import Download from './Download.js';
import Upload from './Upload.js';

import {useRequireAuth} from '../hooks/useFileworksAuth';

function Docs() {
  const auth = useRequireAuth();
  const {uuid} = useParams();

  const { loading, error, get, cache } = useFetch(`downloads/${uuid}`, {cacheLife: 1});

  const [coolFiles, setCoolFiles] = useState([]);

  const unshiftFiles = (files) => {
    setCoolFiles([...files, ...coolFiles]);
  };
  
  const fetchData = async () => {
    const data = await get();
    setCoolFiles(data.files || []);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Upload reloadFiles={fetchData} pushFiles={unshiftFiles} />
      </Row>
      <Row>
        <Download files={coolFiles} />
      </Row>
    </Container>
  );
}

export default Docs;
