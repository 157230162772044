import config from './config.js';

const endpoint = path => `${config.apiUrl}/${path}`;

const upload = (path, {formData, jwt, onprogress,}) => {
  // https://stackoverflow.com/questions/54903511/file-upload-progress-using-react-dropzone
  const xhr = new XMLHttpRequest();
  const url = endpoint(path);

  xhr.open('POST', url);
  xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);

  const updateProgress = event => {
    console.log(event);
    const percentages = +((event.loaded / event.total) * 100).toFixed(2);
    onprogress({percentages})
  };

  xhr.upload.addEventListener('progress', updateProgress, false);

  const promise = new Promise((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return;
      if (xhr.status !== 200) {
        const error = `Status was ${xhr.status}`;
        return reject({error, xhr});
      }
      const complete = true;
      return resolve({complete, xhr});
    };
  });

  xhr.send(formData) // make it so

  return promise;
};

export default upload
