
export function configureFetchWithJwt(jwt, signOut) {
  return {
    interceptors: {
      request: ({ options }) => {
        if (jwt) {
          options.headers = {
            Authorization: `Bearer ${jwt}`,
            ...options.headers,
          }
        }
        return options;
      },
      response: ({ response }) => {
        if (response.status === 401) {
          signOut();
        }
        return response;
      }
    }
  }
}
