import React, {useState, useEffect} from 'react';
import {
  Alert,
  Container,
  Table,
} from 'react-bootstrap';
import useFetch from 'use-http';

import {useRequireAuth} from '../hooks/useFileworksAuth';

const LoadingAccounts = () => {
  return (
    <tr>
      <td colSpan="3">
        Accounts are loading...
      </td>
    </tr>
  )
};

const Accounts = ({accounts}) => {
  return accounts.map((account) => {
    const {uuid} = account;
    return (
      <tr key={uuid}>
        <td>
          <a href={`#/docs/${uuid}`}>
            {account.description}
          </a>
        </td>
        <td>{account.label}</td>
        <td>{account.uuid}</td>
      </tr>
    );
  });
};

const Error = ({error}) => {
  if (!error) {
    return null;
  }

  return (
    <Alert variant='danger'>
      An Unexpected Error Has Occurred.
    </Alert>
  );
};

function Dashboard() {
  const {isSignedOut} = useRequireAuth();

  const { loading, error, data={} } = useFetch(`accounts`, {}, []);
  const { accounts=[] } = data;

  if (isSignedOut) {
    return null; // render nothing, prevent xhr from getting cancelled and triggering an exception
  }

  return (
    <Container>
      <Error {...{error}} />
      <Table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Record ID</th>
            <th>UUID</th>
          </tr>
        </thead>
        <tbody>
          {loading ? <LoadingAccounts /> : <Accounts {...{accounts}}/>}
        </tbody>
      </Table>
    </Container>
  );
}

export default Dashboard;
