import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';

const EmptyCol4 = () => <Col lg={4}>&nbsp;</Col>;

function CheckEmail() {
  return (
    <Container className='text-center'>
      <Row>
        <EmptyCol4 />
        <Col lg={4}>
          <form className="single-question">
            <h2 className="title">Check Your Email</h2>
            <p className="lead">
              You will receive a link in your email that will allow you to access
              the document portal for 15 minutes.
            </p>
          </form>
        </Col>
        <EmptyCol4 />
      </Row>
    </Container>
  );
}

export default CheckEmail;
