import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';

import ReCAPTCHA from './ReCAPTCHA.js';

const EmptyCol4 = () => <Col lg={4}>&nbsp;</Col>;

function AuthCode() {
  const [hex, setHex] = useState('');
  const [errMsg, setErrMsg] = useState();
  const [token, setToken] = useState();
  const history = useHistory();

  const { loading, error, get } = useFetch(`claim/${hex}?token=${token}`);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (hex.length < 1) {
      return null;
    }

    const data = await get();

    if (data.uuid) {
      const {uuid} = data;
      history.push(`/claim/${uuid}`);
    }
    else if (data.error) {
      setErrMsg(data.error);
    }
    else {
      // @TODO better erroring
      setErrMsg('An Unexpected Server Error Has Occured');
    }
  }

  const hint = (
    <>
      <span>
        The pin can be found on the letter you received.
      </span>
      <br/>
      <a href="#/auth/email">
        Already used your code? Click here to enter your email.
      </a>
    </>
  )

  const submitButton = (
    <Button variant="primary" type="submit" className="float-right">
      Submit
    </Button>
  )

  return (
    <Container>
      <Row>
        <EmptyCol4 />

        <Col lg={4}>
          <Form onSubmit={handleSubmit} className="single-question">
            <h2 className="title">Register With Your Access Code</h2>
            <Form.Group controlId="formAuthHex">
              <Form.Label>Enter Upload Access Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your Access Code Will Be 10 Characters"
                onChange={(e) => setHex(e.target.value)}
              />
              <Form.Text className={`text-${errMsg ? 'danger' : 'muted'}`}>
                {errMsg || hint}
              </Form.Text>

            </Form.Group>

            <ReCAPTCHA {...{setToken}} />

            {token && submitButton}
          </Form>
        </Col>

        <EmptyCol4 />
      </Row>
    </Container>
  );
}

export default AuthCode;
