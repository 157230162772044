import React, {useCallback} from 'react'
import {useParams} from 'react-router-dom';
import {useDropzone} from 'react-dropzone'
import {
  Row,
  Col,
} from 'react-bootstrap';

import uploadXhr from '../upload-xhr';
import {useFileworksAuth} from '../hooks/useFileworksAuth';

function Upload({pushFiles, reloadFiles}) {
  const {uuid} = useParams();
  const {jwt} = useFileworksAuth();


  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => file.pending = true);
    pushFiles(acceptedFiles);

    const uploads = acceptedFiles.map(file => {
      const onprogress = ({percentages}) => {
        console.log(percentages, file.name);
        file.percent = percentages;
      };

      console.log(file)

      const formData = new FormData();
      formData.append('doc', file);

      // @TODO call in useEffect?
      return uploadXhr(`upload/${uuid}`, {formData, jwt, onprogress,});
    });

    Promise.all(uploads).then((results) => reloadFiles());
  });

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  const dragClassName = isDragActive ? 'drag-active' : 'drag-inactive';

  const dragHere = (
    <div>
      <h1>Drag and drop files here</h1>
      <p className="lead">(or click to select)</p>
    </div>
  );

  const dropHere = (
    <div>
      <h1>Drop files</h1>
      <p className="lead">(drag away to cancel)</p>
    </div>
  );

  return (
    <Col
      lg={12}
      className={`container-upload text-center ${dragClassName}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      { isDragActive ? dropHere : dragHere }
    </Col>
  );
}

export default Upload;
