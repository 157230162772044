import React from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';


import {useFileworksAuth} from '../hooks/useFileworksAuth';

import rcLogo from '../images/rc-logo-bulb-only-blue.png';

function Header() {
  const {user} = useFileworksAuth();
  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
        <Navbar.Brand href="#">
          <img
            alt="Reason Consulting Corporation"
            src={rcLogo}
            height="30"
            className="d-inline-block align-top"
          />
          &nbsp;
          Reason Consulting Document Portal
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

          {/* <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse> */}
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            {user ? `Signed in as: ${user.email}` : ""}
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header;
