import React, { useState } from 'react';
import {
  useHistory,
  useParams,
  useQuery,
} from 'react-router-dom';
import {
  Button,
  Col, 
  Container,
  Form, 
  Row, 
} from 'react-bootstrap';
import useFetch from 'use-http';

const EmptyCol4 = () => <Col lg={4}>&nbsp;</Col>;

function AuthEmail() {
  const [email, setEmail] = useState('');
  const {uuid} = useParams();
  const history = useHistory();

  const { loading, error, post } = useFetch(`login`)

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = await post({email});

    if (data.ok) {
      history.push('/check-email');
    } 
    else if (data.error) {
      alert(data.error);
    }
    else {
      // display error
      // @TODO better
      alert('An Unexpected Server Error Occurred');
    }
  }

  return (
    <Container>
      <Row>
        <EmptyCol4 />

        <Col lg={4}>
          <Form onSubmit={handleSubmit} className="single-question">
            <h2 className="title">Sign In With Your Email</h2>
            <Form.Group controlId="formEmail">
              <Form.Label>Enter Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="email@example.com"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text className="text-muted">
                Enter the email address you registered with previously.
              </Form.Text>

            </Form.Group>
            <Button variant="primary" type="submit" className="float-right">
              Submit
            </Button>
          </Form>
        </Col>

        <EmptyCol4 />
      </Row>
    </Container>
  );
}

export default AuthEmail;
