import React from 'react';
import {Container} from 'react-bootstrap';

function Footer() {
  const now = new Date();
  const year = now.getFullYear();

  return (
    <footer className="footer">
      <Container className="text-muted text-center">
        Copyright &copy; <a href="https://reason.consulting" target="_blank">Reason Consulting Corporation</a> {year}
      </Container>
    </footer>
  )
}

export default Footer;
