import React, {useEffect} from 'react';
import config from '../config.js';

window.setReCAPTCHAToken = () => {};

function ReCAPTCHA({setToken}) {
  const {reCAPTCHASiteKey} = config;

  window.setReCAPTCHAToken = (t) => { console.log(t); setToken(t); }

  useEffect(() => {
    const reCaptchaUrl = 'https://www.google.com/recaptcha/api.js'
    const script = document.createElement('script');
    script.src = reCaptchaUrl;
    document.body.appendChild(script);
  });

  return (
    <div className="text-center">
      <div
        className="d-inline-block g-recaptcha"
        data-sitekey={reCAPTCHASiteKey}
        data-callback='setReCAPTCHAToken'
      ></div>
    </div>
  );
}

export default ReCAPTCHA;
