const config = {

  'localhost': {
    apiUrl: 'http://localhost:2234',
    reCAPTCHASiteKey: '6LfxA-0iAAAAAAt3JQta1F4QrjhrwzAG21GsxoO5',
    oAuthClientId: '353149360865-an4qe20rd71278h4db01u5jvh40f8dqq.apps.googleusercontent.com',
  },

  'qa-fileworks.auditaware.app': {
    apiUrl: 'https://qa-fileworks-api.auditaware.app',
    reCAPTCHASiteKey: '6LfxA-0iAAAAAAt3JQta1F4QrjhrwzAG21GsxoO5',
    oAuthClientId: '353149360865-an4qe20rd71278h4db01u5jvh40f8dqq.apps.googleusercontent.com',
  },

  'fileworks.auditaware.app': {
    apiUrl: 'https://fileworks-api.auditaware.app',
    reCAPTCHASiteKey: '6LcqBe0iAAAAAGJOLnJ_vpWCEaPV6nb3rNDLnQLD',
    oAuthClientId: '1024211857609-snim8nei4bshenefjbr9p0vmemd59mo2.apps.googleusercontent.com',
  }

};

// remove port for dev / test config
const host = window.location.host.match(/^localhost/) ? 'localhost' : window.location.host;

export default config[host];
