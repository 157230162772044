import React, { useEffect, useState, useContext, useReducer, createContext } from 'react';
import {useHistory} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';

import config from '../config.js';
import {localStorageForKey} from '../localStorageForKey';

const FileworksAuthContext = createContext({});

const initialState = {
  jwt: null,
  user: null,
  isSignedOut: null,
};

const {
  getLocalStorageJwt, setLocalStorageJwt, unsetLocalStorageJwt, tryJwtDecode,
} = localStorageForKey('fileworksToken');

const getFileworksHashParams = () => queryString.parse(window.location.hash);
const isFileworksTokenInHash = () => !!getFileworksHashParams().token;

function reducer(state, action) {
  const {payload, type} = action;
  switch(type) {
    case 'setJwt':
      setLocalStorageJwt(payload);
      // fallthrough to setUser to avoid double render
    case 'setUser':
      const jwt = getLocalStorageJwt();
      const claims = tryJwtDecode(jwt);
      const expiresAt = parseExp(claims.exp);
      const user = {email: claims.sub, expiresAt}

      if (claims && expiresAt > new Date()) {
        return {jwt, user, isSignedOut: false}
      }
      // else fallthrough to signout and reset to initial state
    case 'signOut':
      unsetLocalStorageJwt();
      return {...initialState, isSignedOut: true};
    default:
      throw new Error();
  }
}

const parseExp = (exp) => {
  const date = new Date(0);
  date.setUTCSeconds(exp);
  return date;
}

function useProvideAuth() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const signOut = () => {
    dispatch({type: 'signOut'})
  }

  const signIn = () => {
    if (isFileworksTokenInHash()) {
      const {token, redirect} = getFileworksHashParams()
      window.location.hash = redirect;
      dispatch({type: 'setJwt', payload: token});
      return;
    }

    const jwt = getLocalStorageJwt();
    if (state.isSignedOut === null && jwt) {
      dispatch({type: 'setUser'});
      return;
    }
  }

  return {...state, signIn, signOut};
}

export function ProvideFileworksAuth({children}) {
  const auth = useProvideAuth();
  auth.signIn(); // capture token in hash before router redirects

  return (
    <FileworksAuthContext.Provider value={auth}>
      {children}
    </FileworksAuthContext.Provider>
  );
}

export const useFileworksAuth = () => {
  return useContext(FileworksAuthContext);
}

export function useRequireAuth(redirectUrl = '/auth/email'){
  const auth = useFileworksAuth();
  const history = useHistory();

  useEffect(() => {
    if (auth.isSignedOut === true){
      history.push(redirectUrl);
    }
  }, [auth, history]);
    
  return auth;
}

