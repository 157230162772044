import React from 'react';
import {Container} from 'react-bootstrap';
import {Provider as FetchProvider} from 'use-http';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Header from './Header.js';
import Footer from './Footer.js';
import AuthCode from './AuthCode.js';
import AuthEmail from './AuthEmail.js';
import Docs from './Docs.js';
import Claim from './Claim.js';
import Dashboard from './Dashboard.js';
import CheckEmail from './CheckEmail.js';

import {ProvideFileworksAuth, useFileworksAuth} from '../hooks/useFileworksAuth';

import config from '../config';
import {configureFetchWithJwt} from '../configureFetchWithJwt';

import '../styles/App.scss';

function AuthApp() {
  return (
    <ProvideFileworksAuth>
      <App />
    </ProvideFileworksAuth>
  );
}

function App() {
  const {signOut, jwt, user} = useFileworksAuth();
  const fetchOptions = configureFetchWithJwt(jwt, signOut);

  return (
    <FetchProvider url={config.apiUrl} options={fetchOptions}>
      <div id="App">
        <Header />

        <Router>
          <Switch>
            <Route exact path="/auth/code">
              <AuthCode />
            </Route>
            <Route exact path="/auth/email">
              <AuthEmail />
            </Route>
            <Route exact path="/claim/:uuid">
              <Claim />
            </Route>
            <Route exact path="/check-email">
              <CheckEmail />
            </Route>
            <Route exact path="/dashboard">
              <Dashboard />
            </Route>
            <Route exact path="/docs/:uuid">
              <Docs />
            </Route>
            <Route path="">
              <Redirect to={user ? '/dashboard' : '/auth/code'} />
            </Route>
          </Switch>
        </Router>

        <Footer />
      </div>
    </FetchProvider>
  );
}

export default AuthApp;
